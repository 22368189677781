import React, { Fragment } from "react"
import HeroImg from "../../images/hero.svg"
import Icon from "../../images/icons/Icons"

import styled from "styled-components"

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: inherit;
  min-height: calc(100vh - 65px);
  width: 100%;

  @media (min-width: ${props => props.theme.sizes.tablet}) {
    flex-direction: row;
  }
`

const TextContainer = styled.div`
  position: relative;
  text-align: center;
  z-index: 1;
  width: auto;
  border-bottom: 4px solid transparent;

  p {
    font-size: 1.2rem;
    span {
      font-size: 1.2rem;
    }
  }

  .name {
    font-size: 4rem;
    margin-top: 0.5rem;
    margin-left: -7px;
    font-weight: 300;
    line-height: 0.9;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-size: 1.5rem;
  }

  @media (min-width: ${props => props.theme.sizes.tablet}) {
    max-width: 500px;
    text-align: left;
    width: 60%;
    p {
      font-size: 1.5rem;
      span {
        font-size: 1.5rem;
      }
    }
    .name {
      font-size: 6rem;
    }
    h3 {
      font-size: 2rem;
    }
  }

  @media (min-width: ${props => props.theme.sizes.mobile}) {
    max-width: 500px;
    width: 60%;
  }
`

const UnderLine = styled.span`
  padding: 2px 0px;
  & {
    animation-name: underline;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    position: relative;

    text-decoration: none;
    background-size: 100% 4px, 0 4px;
    background-image: linear-gradient(#fffbdb, #fffbdb),
      linear-gradient(${props => props.color}, ${props => props.color});

    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
  }
  @keyframes underline {
    from {
      background-size: 100% 4px, 0 4px;
    }
    to {
      background-size: 0 4px, 100% 4px;
    }
  }
`

export const ContactContainer = styled.section`
  a:hover {
    color: black !important;
  }

  svg {
    margin-right: 1rem;
  }

  .blue:hover path,
  .blue:hover rect,
  .blue:hover circle {
    fill: #bfd9ff;
  }

  .green:hover path,
  .green:hover rect,
  .green:hover circle {
    fill: #c2e3a2;
  }

  .red:hover,
  .red:hover path {
    fill: #f3b0c3;
  }
`

const ImgContainer = styled.div`
  margin-bottom: 1rem;
  svg {
    width: 100%;
    max-width: 340px;
  }

  #inner-color {
    transition-delay: ${props => (props.isColor ? "1.5s" : "none")};
    fill: ${props => (props.isColor ? "" : "#fff")};
  }

  #open {
    animation: blink 10s ease-in infinite;
    opacity: 1;
  }

  @keyframes blink {
    6%,
    9%,
    70%,
    73% {
      opacity: 1;
    }
    8%,
    72% {
      opacity: 0;
    }
  }

  #close {
    animation: c-blink 10s ease-in infinite;
    opacity: 0;
  }

  @keyframes c-blink {
    6%,
    9%,
    70%,
    73% {
      opacity: 0;
    }
    8%,
    72% {
      opacity: 1;
    }
  }

  @media (min-width: ${props => props.theme.sizes.tablet}) {
    margin-bottom: 0rem;
  }
  @media (min-width: ${props => props.theme.sizes.mobile}) {
    margin-bottom: 1rem;
    svg {
      width: 450px;
    }
  }
`

export const contact = isColor => {
  const isFill = isColor ? "none" : "#fff"
  return (
    <Fragment>
      <a
        href="https://linkedin.com/in/esthleej"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="linkedin"
      >
        <Icon
          fill={isFill}
          name="linkedin"
          className="blue"
          width="30"
          height="30"
        />
      </a>
      <a
        href="https://github.com/esthleej"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="github"
      >
        <Icon
          fill={isFill}
          name="github"
          className="green"
          width="30"
          height="30"
        />
      </a>
      <a href="mailto:estherleedev@gmail.com" aria-label="email">
        <Icon
          fill={isFill}
          name="mail"
          className="red"
          width="30"
          height="30"
        />
      </a>
    </Fragment>
  )
}

const Hero = ({ isColor }) => (
  <HeroContainer id="about">
    <TextContainer>
      <h3>Hello, my name is</h3>
      <p className="name">Esther Lee.</p>
      {/* <p>
        I am a highly-motivated&nbsp;
        <UnderLine color="#c6dbda">Software Engineer</UnderLine>&nbsp;
        from&nbsp;<UnderLine color="#c2e3a2">California</UnderLine>
        &nbsp;who&nbsp;loves to build things with&nbsp;
        <UnderLine color="#f3b0c3">code</UnderLine>.
      </p> */}
      <p>
        I am a <UnderLine color="#c6dbda">Software Engineer</UnderLine> from{" "}
        <UnderLine color="#c2e3a2">California</UnderLine>&nbsp; who&nbsp;
        <UnderLine color="#f3b0c3">loves</UnderLine> to build clean, modern web
        applications.
      </p>
      <ContactContainer>{contact(isColor)}</ContactContainer>
    </TextContainer>
    <ImgContainer isColor={isColor}>
      <HeroImg />
    </ImgContainer>
  </HeroContainer>
)

export default Hero
