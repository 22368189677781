import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  color: gray;
  background-color: ${props => props.footerColor};
  padding-bottom: 1rem;
  font-weight: 300;
  font-size: 1rem;
`

const Footer = ({ footerColor }) => {
  const today = new Date()
  const year = today.getFullYear()

  return (
    <FooterContainer footerColor={footerColor}>
      &copy; {year} Esther Lee
    </FooterContainer>
  )
}

export default Footer

Footer.propTypes = {
  footerColor: PropTypes.string.isRequired,
}
