import styled from "styled-components"

export const NavbarContainer = styled.nav`
  transition: height 0.2s;
  visibility: visible;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 250px;
  background-color: ${props => props.navbarColor};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: ${props => (props.open ? "calc(100vh - 0rem)" : "6rem")};

  align-items: inherit;
  @media (min-width: ${props => props.theme.sizes.mobile}) {
    flex-direction: row;
    padding: 3rem 4rem 3rem 4rem;
    justify-content: space-between;
    border-width: 1rem 1rem 0rem 1rem;
    align-items: center;
    height: 0%;
  }

  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;

    .home {
      h3 {
        margin-bottom: 0;
      }

      &:hover {
        animation: shake 0.5s;
      }

      @keyframes shake {
        0% {
          transform: rotate(0deg);
        }
        80% {
          transform: rotate(0deg);
        }
        85% {
          transform: rotate(10deg);
        }
        95% {
          transform: rotate(-10deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
    }
  }
`

export const HamburgerButton = styled.button`
  display: block;
  height: 1.4rem;
  width: 2rem;
  position: relative;
  &:focus {
    outline: none;
  }
  @media (min-width: ${props => props.theme.sizes.mobile}) {
    display: none;
  }

  div {
    position: relative;
    &:before,
    &:after {
      position: absolute;
      background: ${props => props.theme.colors.primary};
      height: 2px;
      content: "";
      width: 100%;
      left: 0;
      transition: all 0.2s ease;
      top: ${props => (props.open ? "0rem" : "-0.5rem")};
      transform: ${props => (props.open ? "rotate(45deg)" : "rotate(0deg)")};
    }
    &:after {
      transform: ${props => (props.open ? "rotate(-45deg)" : "rotate(0deg)")};
      top: ${props => (props.open ? "0rem" : "0.5rem")};
    }
  }
`

export const LinkContainer = styled.div`
  padding-top: 0px;

  @media (max-width: ${props => props.theme.sizes.mobile}) {
    padding-top: 12px;
    .contact {
      position: relative;
      left: -100px;
      width: 100px;
      animation: slide 0.5s forwards;
      section {
        display: flex;
        margin-left: 100px;
        &:after {
          animation-name: line;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          height: 3px;
          width: 80px;
          margin-top: -2px;
          visibility: visible;
          background-color: lightsalmon;
        }
        svg {
          transition: width 0.2s ease;
          width: 22px;
          &:nth-child(1) {
            transition-delay: ${props => (props.open ? "0.2s" : "0s")};
          }
          &:nth-child(2) {
            transition-delay: ${props => (props.open ? "0.4s" : "0s")};
          }
          &:nth-child(3) {
            transition-delay: ${props => (props.open ? "0.6s" : "0s")};
          }
        }
      }

      @keyframes slide {
        100% {
          left: 0;
        }
      }

      @keyframes line {
        from {
          visibility: hidden;
          width: 0;
        }
        to {
          visibility: visible;
          width: 80px;
        }
      }
    }
  }
  .links-content {
    visibility: ${props => (props.open ? "visible" : "hidden")};
    display: flex;
    flex-direction: column;

    a {
      width: 70px;
      span {
        font-size: 1.5rem;
      }
      transition: ${props => (props.open ? "all 0.3s ease-out" : "none")};
      margin-left: 0rem;
      padding-top: ${props => (props.open ? "12px" : "0px")};
      opacity: ${props => (props.open ? "1" : "0")};
      &:nth-child(1) {
        transition-delay: ${props => (props.open ? "0.2s" : "0s")};
      }
      &:nth-child(2) {
        transition-delay: ${props => (props.open ? "0.4s" : "0s")};
      }
      &:nth-child(3) {
        transition-delay: ${props => (props.open ? "0.6s" : "0s")};
      }
      &:nth-child(4) {
        transition-delay: ${props => (props.open ? "0.8s" : "0s")};
      }
    }
  }

  @media (min-width: ${props => props.theme.sizes.mobile}) {
    display: flex;

    .links-content {
      visibility: visible;
      display: flex;
      flex-direction: row;
      a {
        margin-left: 1rem;
        padding-top: 0px;
        opacity: 1;
      }
    }
  }
`
export const Underline = styled.span`
  display: inline-block;
  cursor: pointer;
  &:after {
    content: "";
    animation-duration: 1s;
    animation-fill-mode: forwards;
    height: 4px;
    width: 0%;
    bottom: 0;
    left: 0;
    display: block;
    background-color: ${props => props.color};
    transition: all 0.2s ease-in-out;
  }
  &:hover:after {
    width: 100%;
    background-color: ${props => props.color};
    opacity: 1;
  }
`
