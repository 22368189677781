import React, { useState } from "react"
import PropTypes from "prop-types"

import styled, { ThemeProvider } from "styled-components"
import GlobalStyle from "../styles/Global"
import Theme from "../styles/Theme"
import Reboot from "../styles/reboot"

import NavBar from "../components/navbar/Navbar"
import Footer from "../components/footer/Footer"

const LayoutStyles = styled.div`
  display: flex;
  flex-direction: column;
  overflow: ${props => (props.openMenu ? "hidden" : "visible")};
  margin: 0rem;
  min-width: 250px;
  background-color: inherit;
`

const Layout = ({ children, location }) => {
  const [openMenu, setOpenMenu] = useState(false)

  let navbarColor = "#fffbdb"
  let footerColor = "#fff1ed"

  if (location.pathname !== "/") {
    navbarColor = "#f5edff"
    footerColor = "#f5edff"
  }

  return (
    <LayoutStyles openMenu={openMenu}>
      <Reboot />
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <NavBar
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          navbarColor={navbarColor}
        />
        {children}
        <Footer footerColor={footerColor} />
      </ThemeProvider>
    </LayoutStyles>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Layout
