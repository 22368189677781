import { createGlobalStyle } from "styled-components"

const Global = createGlobalStyle`
  html,
  body {
    scroll-behavior: smooth;
  }

  a, li, span, small, div {
      font-family: ${props => props.theme.fonts.body};
      font-size: 1rem;
  }
  span, div {
    font-family: ${props => props.theme.fonts.body};
    font-size: 1.1rem;
  }

  a {
    color: ${props => props.theme.colors.primary} !important;
    :hover {
      text-decoration: none !important;
      color: ${props => props.theme.colors.secondary};
      
    }
  }
 
  h1, h2, h3, h5, h6, footer {
    font-family:${props => props.theme.fonts.body};
  }

  h1 {
    font-size: 3.157rem;
    font-weight: 300;
    line-height: 0.9;
    margin-bottom: 0rem;
    font-family:${props => props.theme.fonts.body} !important;
  }

  h2 {
    font-size: 2.369rem;
    font-weight: 300;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1;
    margin-bottom:0rem;
  }

  h4 {
    font-weight: 500;
  }

  p {
    font-weight: 300;
    font-size: 1.1rem;

  }

  footer {
    font-size: 1rem;
    font-weight: 300;
  }
  
  button {
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
  }

  html { 
  font-family: ${props => props.theme.fonts.body}; 
  font-weight: 300;
  }

  span{
    font-weight: 300;
  }

  @supports (font-variation-settings: normal) {
    html { 
    font-family: ${props => props.theme.fonts.body}; 
    }
  }
`

export default Global
