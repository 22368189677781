import "typeface-noto-sans"
import "typeface-lato"

const Theme = {
  colors: {
    background: "#fff",
    text: "#000",
    primary: "#000",
    secondary: "#aaa",
  },
  fonts: {
    body: '"Lato", sans-serif',
    header: '"Noto-sans", sans-serif',
  },
  sizes: {
    xmobile: "450px",
    mobile: "650px",
    tablet: "950px",
    large: "1118px",
  },
}

export default Theme
