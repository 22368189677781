import React, { useEffect, Fragment } from "react"
import { Link } from "gatsby"

import { contact, ContactContainer } from "../hero/Hero"

import * as S from "./Navbar.styled"

const NavBar = ({ openMenu, setOpenMenu, navbarColor }) => {
  // const handleScrollToTop = () => {
  //   window.scrollTo(0, 0)
  // }
  const handleOpenMenu = () => {
    setOpenMenu(!openMenu)
  }

  const handleResize = () => {
    setOpenMenu(false)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const renderNavbarItems = () => {
    return (
      <Fragment>
        <a href="#about" onClick={() => setOpenMenu(false)}>
          <S.Underline color="#c6dbda">About</S.Underline>
        </a>
        <a href="#works" onClick={() => setOpenMenu(false)}>
          <S.Underline color="#c2e3a2">Works</S.Underline>
        </a>
        <a href="#contact" onClick={() => setOpenMenu(false)}>
          <S.Underline color="#f3b0c3">Contact</S.Underline>
        </a>
        {/* <Link to="/blog">
          <S.Underline color="#f3b0c3">Blog</S.Underline>
        </Link> */}
      </Fragment>
    )
  }

  return (
    <S.NavbarContainer open={openMenu} navbarColor={navbarColor}>
      <div className="navbar">
        <Link to="/" className="home">
          <h3>esther</h3>
        </Link>

        <S.HamburgerButton
          aria-label="menu"
          onClick={handleOpenMenu}
          type="submit"
          open={openMenu}
        >
          <div />
        </S.HamburgerButton>
      </div>

      <S.LinkContainer aria-hidden={openMenu} open={openMenu}>
        {openMenu && (
          <Fragment>
            <div className="contact">
              <ContactContainer>{contact(true)}</ContactContainer>
            </div>
          </Fragment>
        )}
        <div className="links-content">{renderNavbarItems()}</div>
      </S.LinkContainer>
    </S.NavbarContainer>
  )
}

export default NavBar
